@import 'node_modules/modern-normalize/modern-normalize';

$clr-green: #2fced1;
$clr-blue: #4d62dc;

$breakpoint: 55em;

::selection {
    background-color: rgba($clr-green, 1);
    color: #fff;
}

html {
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #000 url(./assets/circuitboard.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size: 1.1em;
    line-height: 1.5;
    -webkit-text-size-adjust: none;

    color: #fff;
}

a {
    color: $clr-green;
    transition: color 0.2s;
    text-decoration: none;

    &:hover {
        color: lighten($clr-blue, 10%);
        text-decoration: underline;
    }
}

.home {
    @media (min-width: $breakpoint) {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        @media (min-width: $breakpoint) {
            width: 50em;
        }
    }

    &__wrap {
        display: flex;

        @media (max-width: $breakpoint) {
            padding: 2em 1em;
            flex-direction: column-reverse;
        }

        @media (min-width: $breakpoint) {
            justify-content: space-between;
            align-items: flex-end;
        }
    }
}

.logo {
    @media (max-width: $breakpoint) {
        margin: 2em;
        height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__slogan {
            font-size: 1em;
        }
    }

    @media (min-width: $breakpoint) {
        margin: 0 auto 30% auto;
        width: 100%;
    }

    &__image img {
        display: block;
        width: 100%;
    }

    &__slogan {
        text-align: center;
        font-weight: normal;
    }
}

.connect {
    font-size: 0.9em;
    opacity: 0.9;
    font-weight: 300;
    flex: 1;
    transition: opacity 0.3s;

    @media (max-width: $breakpoint) {
        text-align: center;
    }

    &:hover {
        opacity: 1;
    }

    &__title {
        text-transform: uppercase;
        font-weight: 300;
        margin: 0;
        font-size: 0.9em;
    }
}

.about-us {
    flex: 3;
    position: relative;

    @media (max-width: $breakpoint) {
        margin-bottom: 2em;
    }

    @media (min-width: $breakpoint) {
        margin-left: 1.25em;
        border-left: 1px solid #fff;
        padding-left: 1.25em;
    }

    &__title {
        margin-top: -0.3em;
        margin-bottom: 0.5em;
        font-size: 1.3em;
        line-height: 1.2;

        @media (min-width: $breakpoint) {
            font-size: 1.9em;
        }
    }

    &__text {
        margin: 0;
        font-weight: 300;
    }
}
